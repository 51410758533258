import '@fortawesome/fontawesome-free/css/all';
import Vue from 'vue/dist/vue.esm';
import PanelsSlide from '../components/panels-slide';
import Panels3DSlide from '../components/panels-3d-slide';
import '../css/application.css';

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#vue-app',
    components: {
      'panels-slide': PanelsSlide,
      'panels-3d-slide': Panels3DSlide,
    },
  });

  return app;
});
