<template lang="html">
  <div class="mt-12 pb-12">
    <carousel-3d :perspective="0" :count="5" :display="3" :inverse-scaling="2000" :controls-visible="true" :height="height" :width="width" bias="right" :border="0" :space="space">
      <slide :index="0">
        <img src="./images/flows/1.svg" alt="">
      </slide>
      <slide :index="1">
        <img src="./images/flows/2.svg" alt="">
      </slide>
      <slide :index="2">
        <img src="./images/flows/3.svg" alt="">
      </slide>
      <slide :index="3">
        <img src="./images/flows/4.svg" alt="">
      </slide>
      <slide :index="4">
        <img src="./images/flows/5.svg" alt="">
      </slide>
    </carousel-3d>
  </div>
</template>

<script>

import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: 'panels-3d-slide',
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      height: window.innerHeight * .7,
      width: window.innerWidth * .55,
      space: window.innerWidth * .95,
      controlsWidth: window.innerWidth,
    };
  },
};
</script>

<style lang="scss">
  .carousel-3d-slide {
    background: transparent !important;
    display: flex !important;
  }
</style>

<style lang="scss" scoped>
</style>
