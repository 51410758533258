
<template lang="html">
  <div class="slider-wrapper">
    <carousel :navigation-enabled="true" navigation-next-label="❯" navigation-prev-label="❮" :per-page="1">
      <slide>
        <div class="slide-panels-container flex justify-center">
          <div class="low-fid-panel px-2 pb-2">
            <div class="inline">Low-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/1-a.jpeg" alt="">
            </div>
          </div>
          <div class="high-fid-panel px-2 pb-2">
            <div class="inline">High-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/1-b.jpeg" alt="">
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="slide-panels-container flex justify-center">
          <div class="low-fid-panel px-2 pb-2">
            <div class="inline">Low-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/2-a.jpeg" alt="">
            </div>
          </div>
          <div class="high-fid-panel px-2 pb-2">
            <div class="inline">High-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/2-b.jpeg" alt="">
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="slide-panels-container flex justify-center">
          <div class="low-fid-panel px-2 pb-2">
            <div class="inline">Low-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/3-a.jpeg" alt="">
            </div>
          </div>
          <div class="high-fid-panel px-2 pb-2">
            <div class="inline">High-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/3-b.jpeg" alt="">
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="slide-panels-container flex justify-center">
          <div class="low-fid-panel px-2 pb-2">
            <div class="inline">Low-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/4-a.jpeg" alt="">
            </div>
          </div>
          <div class="high-fid-panel px-2 pb-2">
            <div class="inline">High-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/4-b.jpeg" alt="">
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="slide-panels-container flex justify-center">
          <div class="low-fid-panel px-2 pb-2">
            <div class="inline">Low-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/5-a.jpeg" alt="">
            </div>
          </div>
          <div class="high-fid-panel px-2 pb-2">
            <div class="inline">High-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/5-b.jpeg" alt="">
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="slide-panels-container flex justify-center">
          <div class="low-fid-panel px-2 pb-2">
            <div class="inline">Low-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/6-a.jpeg" alt="">
            </div>
          </div>
          <div class="high-fid-panel px-2 pb-2">
            <div class="inline">High-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/6-b.jpeg" alt="">
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="slide-panels-container flex justify-center">
          <div class="low-fid-panel px-2 pb-2">
            <div class="inline">Low-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/7-a.jpeg" alt="">
            </div>
          </div>
          <div class="high-fid-panel px-2 pb-2">
            <div class="inline">High-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/7-b.jpeg" alt="">
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="slide-panels-container flex justify-center">
          <div class="low-fid-panel px-2 pb-2">
            <div class="inline">Low-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/8-a.jpeg" alt="">
            </div>
          </div>
          <div class="high-fid-panel px-2 pb-2">
            <div class="inline">High-fidelity prototype</div>
            <div>
              <img class="w-full" src="./images/panels/8-b.jpeg" alt="">
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>

import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'panels-slide',
  components: {
    Carousel,
    Slide,
  },
};
</script>

<style lang="scss">
  .VueCarousel-navigation-button {
    border: 1px solid #8a425d !important;
    border-radius: 50%;
    width: 41px;
    height: 41px;
    padding: 0 !important;
    font-size: 25px;
    color: #8a425d !important;

    &:focus {
      outline: none !important;
    }
  }

  .VueCarousel-navigation-next {
    right:-10px;
  }

  .VueCarousel-navigation-prev {
    left:-10px;
  }

  .VueCarousel-dot {
    &--active {
      background-color: #8a425d !important;
    }
    &-container {
      margin-top: 0 !important;

      & > button {
        margin-top: 0 !important;
      }
    }
  }
</style>

<style lang="scss" scoped>

</style>
